<template>
  <FilterPopper v-model:show="isOpen">
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Images With Annotation Types'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <Transition name="fade" mode="out-in">
          <div v-if="internalTypes && internalTypes.length > 0">
            <div v-for="(type, i) in internalTypes" :key="i" class="filter-settings__row">
              <input
                :id="`annotationTypeFilter-type-${i}`"
                v-model="selectedTypes"
                type="checkbox"
                :name="`${type}-${i}`"
                :value="type"
              >
              <label :for="`${type}-${i}`">{{ getTypeDisplayString(type) }}</label><br>
            </div>
          </div>
          <div v-else-if="internalTypes">
            No annotation type
          </div>
          <div v-else>
            <v-skeleton-loader
              type="list-item"
              theme="primary"
              color="transparent"
            />
            <v-skeleton-loader
              type="list-item"
              theme="primary"
              color="transparent"
            />
          </div>
        </Transition>
      </div>
      <div v-if="selectedTypes.length > 0" class="filter-settings filter-settings-extra">
        <div class="filter-settings__row">
          <input
            :id="`annotationTypeFilter-type-invert`"
            v-model="isInvertSelected"
            type="checkbox"
            :name="`invert`"
          >
          <label :for="`invert`">Invert Selection</label>
        </div>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import useAnnotationType from '@/composables/useAnnotationType.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "AnnotationTypeFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'update:invert',
  ],
  setup() {
    const {
      getTypeDisplayString,
    } = useAnnotationType();
    return {
      getTypeDisplayString,
    };
  },
  data() {
    return {
      isOpen: false,
      selectedTypes: [],
      internalTypes: null,
      isInvertSelected: false,
    };
  },
  computed: {
    isActive() {
      return this.selectedTypes.length > 0;
    },
    tagValue() {
      return this.selectedTypes.length.toString();
    },
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
  },
  watch: {
    types: {
      deep: true,
      immediate: true,
      handler() {
        this.selectedTypes = [];
      },
    },
    modelValue(newVal) {
      this.selectedTypes = newVal;
    },
    isInvertSelected() {
      this.updateFilters();
    },
    selectedTypes() {
      this.updateFilters();
    },
    isOpen(isOpen) {
      if (isOpen) {
        const dataConnect = new DatastoreConnect();
        dataConnect.getDatasetAnnotationTypes({ dataset_id: this.dataset.id })
          .then((resp) => {
            if (resp.error) {
              throw resp.error;
            }
            this.internalTypes = resp.result;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  mounted() {
    this.isInvertSelected = this.invert;
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedTypes);

      if (!this.selectedTypes || this.selectedTypes.length === 0) {
        this.isInvertSelected = null;
      }
      this.$emit('update:invert', this.isInvertSelected);
    },
    clearSelection() {
      this.selectedTypes = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  overflow: auto;

  &__row {
      display: flex;
      align-items: center;
  }

  &__row + &__row {
    margin-top: 5px;
  }

  & input[type=checkbox] {
      margin-right: 12px;
  }
}

.filter-settings-extra {
  border-top: 1px solid rgba(140, 140, 140, 0.375);
  border-radius: 0;
}

.filter-footer {
min-height: 38px;
width: 100%;
border-top: 1px solid rgba(140,140,140,0.375);
padding: 8px;
text-align: center;

.clear-button {
    text-align: center;
    @include themify() {
    color: themed('color-danger');
    }

    &[disabled] {
    @include themify() {
        color: themed('color-disabled');
    }
    }
}
}
</style>
