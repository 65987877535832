<template>
  <svg
    :width="width"
    :height="height"
    :aria-labelledby="iconName"
    role="presentation"
    :fill="iconColor"
    :title="title"
    :class="{ 'mirrored' : mirrored }"
  >
    <use
      :href="iconFile"
    />
  </svg>
</template>

<script>

export default {
  name: 'SVGIcon',
  props: {
    iconName: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    title: {
      type: String,
      default: '',
    },
    mirrored: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconFile() {
      return `${require(`@/assets/img/${this.iconName}.svg`)}#${this.iconName}`;
    },
  },
};
</script>

<style scoped>
svg {
  display: block;
}
.mirrored {
  transform:rotate(180deg);
}
</style>
