<template>
  <div
    :id="uid"
    ref="select"
    class="base-select"
    :class="{ 'focused': focused }"
    @click="showMenu = !showMenu"
  >
    <Popper
      :config="menuPopperConfig"
      :show="showMenu"
      :appendToBody="true"
      :trigger="null"
      @popper-closed="handlePopperClosed"
    >
      <template #trigger>
        <div class="base-select__inner" :class="{ 'focused': focused }">
          <fieldset class="base-select__fieldset" :class="{ 'focused': focused }"><legend>{{ legendText }}</legend></fieldset>
          <span class="base-select__value">
            {{ displayTag && value ? value[displayTag] : value }}
          </span>
          <button class="base-select__dropdown-button">
            <SVGIcon
              :mirrored="showMenu"
              :iconName="'expand_more'"
              :width="'20px'"
              :height="'20px'"
            />
          </button>
          <label class="base-select__label" :class="{ 'focused': focused }">{{ placeholder }}</label>
        </div>
      </template>
      <div v-if="options && options.length > 0" class="base-select__dropdown scrollbar" :style="{width: clientWidth}">
        <ul>
          <li v-for="(option, i) in options" :key="i">
            <button
              class="dropdown-button"
              @mousedown="handleMenuButtonClick(option)"
            >
              {{ displayTag && option ? option[displayTag] : option }}
            </button>
          </li>
        </ul>
      </div>
    </Popper>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import Popper from '@/components/Popper.vue';
import { detectOverflow } from '@popperjs/core';

const overflowPadding = 20;

let uid = 0;

export default {
  name: "BaseSelect",
  components: {
    SVGIcon,
    Popper,
  },
  props: {
    modelValue: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    displayTag: {
      type: String,
      default: null,
    },
    flip: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'select'],
  data() {
    uid += 1;
    return {
      uid: `base-select-${uid}`,
      showMenu: false,
      clientWidth: null,
      value: '',
    };
  },
  computed: {
    detectOverflowModifier() {
      return {
        name: 'detectOverflowModifier',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const overflow = detectOverflow(state);
          if (state.placement === 'bottom-start') {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.bottom - overflowPadding}px`;
          }
          if (state.placement === 'top-start') {
            state.styles.popper.maxHeight = `${state.elements.popper.offsetHeight - overflow.top - overflowPadding}px`;
          }
        },
      };
    },
    focused() {
      return this.value;
    },
    legendText() {
      return this.focused ? this.placeholder : '';
    },
    menuPopperConfig() {
      return {
        placement: 'bottom-start',
        modifiers: [
          this.detectOverflowModifier,
          {
            name: 'offset',
            options: {
              offset: [0, 1],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 0,
            },
          },
          {
            name: 'flip',
            enabled: this.flip,
          },
        ],
      };
    },
  },
  watch: {
    modelValue(value) {
      this.value = value;
    },
  },
  mounted() {
    this.value = this.modelValue;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (this.$refs.select) {
        this.clientWidth = `${this.$refs.select.clientWidth}px`;
      }
    },
    handleMenuButtonClick(value) {
      this.$emit('select', value);
      this.value = value;
      this.$emit('update:modelValue', value);
      this.showMenu = false;
    },
    handlePopperClosed() {
      this.showMenu = false;
    },
    handleInput() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
$selectHeight: 40px;
$selectLegendFontSize: 12px;
$denseSelectHeight: 30px;

.base-select {
  position: relative;
  height: 48px;
  background: white;
  cursor: pointer;
  @include themify() {
    color: themed('body-text-color')
  }

  &.focused {
    margin: 0;
  }

  &__inner {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    background: inherit;
  }

  &__fieldset {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 0 8px;
    margin: 0;

    text-align: left;
    font-size: $selectLegendFontSize;
    line-height: 1;

    pointer-events: none;
    border-collapse: collapse;
    border: 1px solid;
    border-radius: 4px;

    transition-duration: .1s;
    transition-property: color,border-width;
    transition-timing-function: cubic-bezier(.25,.8,.25,1);

    &.focused{
      border: 2px solid;
      color: inherit;

      legend {
        padding: 0 4px;
      }
    }

    legend {
      display: flex;
      padding: 0;
      visibility: hidden;
      height: 0;
    }
  }

  &__value {
    background: none;
    width: 100%;
    border: none !important;
    padding: 0px 12px;
    margin: auto;
    text-align: left;
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  &__dropdown-button {
    display: flex;
    align-items: center;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
  }

  &__label {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    font-weight: 500;
    height: fit-content;
    pointer-events: none;
    transition: all .3s cubic-bezier(.25,.8,.5,1);

    @include themify() {
      color: themed('placeholder-text-color')
    }

    &.focused {
      top: 0;
      font-size: $selectLegendFontSize;
      color: inherit;
    }
  }

  &__dropdown {
    width: 100%;
    background: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 40%);
    overflow-y: auto;
    @include themify() {
      outline: 1px solid themed('popper-border-light');
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 0px;
  background: white;
  color: inherit;
  padding: 8px 4px;
  margin: 0;
  line-height: 1;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: var(--menu-button-hover-background);
  }

  &:focus-visible {
    background-color: var(--menu-button-hover-background);
  }

  > span {
    font: inherit;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 12px;
    white-space: nowrap;
  }

  &._danger {
    @include themify() {
      color: themed('color-danger');
    }
  }
}

.base-select.dense {
  height: 40px;
}

</style>
