<template>
  <!-- eslint-disable max-len -->
  <BaseGalleryGridItem
    :imageObj="imageObj"
    :annotations="filteredAnnotations"
    :annotationSets="annotationSets"
    :annotationDisplayType="annotationDisplayType"
    :filterAnnotationsBySets="filterAnnotationsBySets"
    :filterAnnotationsByLabelIndexes="filterAnnotationsByLabelIndexes"
    @click="handleImageClicked"
    @mouseover="mouseHover=true"
    @mouseleave="mouseHover=false"
  >
    <template #header>
      <div class="gallery-card-header">
        <div v-if="shouldShowSelector" class="item-selector">
          <v-checkbox
            v-model="internalSelectedImages"
            :value="imageObj"
            :hide-details="true"
            density="compact"
            @click.stop=""
          />
        </div>
        <BaseMenu
          v-if="isSequence"
          :placement="'bottom-end'"
          @closed="show_menu = false"
        >
          <IconButton
            v-model="show_menu"
            :icon="'more_vert'"
            :width="24"
            :height="24"
            class="menu-icon"
          />
          <template #menu="{closeMenu}">
            <ul class="reviewTask-menu">
              <li>
                <BaseMenuButton @click.stop="download(), closeMenu()">
                  <SVGIcon
                    :iconName="'import'"
                    :width="'22px'"
                    :height="'22px'"
                    :title="`Export`"
                  />
                  <span>Export</span>
                </BaseMenuButton>
              </li>
              <li>
                <BaseMenuButton @click.stop="copy(), closeMenu()">
                  <SVGIcon
                    :iconName="'copy_content'"
                    :width="'22px'"
                    :height="'22px'"
                    :title="`Copy`"
                  />
                  <span>Copy</span>
                </BaseMenuButton>
              </li>
              <li>
                <BaseMenuButton @click.stop="handleDeleteClicked(), closeMenu()">
                  <SVGIcon
                    :iconName="'delete'"
                    :width="'22px'"
                    :height="'22px'"
                    :title="`Delete`"
                  />
                  <span>Delete</span>
                </BaseMenuButton>
              </li>
            </ul>
          </template>
        </BaseMenu>
        <BaseMenu
          v-else
          :placement="'bottom-end'"
          @closed="show_menu = false"
        >
          <IconButton
            v-model="show_menu"
            :icon="'more_vert'"
            :width="24"
            :height="24"
            class="menu-icon"
          />
          <template #menu="{closeMenu}">
            <ul class="reviewTask-menu">
              <li>
                <BaseMenuButton @click.stop="downloadImage(), closeMenu()">
                  <SVGIcon
                    :iconName="'download'"
                    :width="'22px'"
                    :height="'22px'"
                    :title="`Download Image`"
                  />
                  <span>Download Image</span>
                </BaseMenuButton>
              </li>
            </ul>
          </template>
        </BaseMenu>
      </div>
    </template>
    <template #image>
      <SVGIcon
        v-if="isSequence"
        class="video-icon"
        :iconName="'play_circle_filled'"
        :width="24"
        :height="24"
      />
    </template>
    <template #content>
      <GalleryGridItemLabelTags
        :labels="labels"
        :imageId="imageObj.id"
        :tags="imageObj.tags"
        :annotationList="filteredAnnotations"
        :showName="showName"
        :showTags="showTags"
        :imgName="imageObj.name"
        :uuid="imageObj.uuid"
      />
    </template>
  </BaseGalleryGridItem>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import BaseGalleryGridItem from '@/components/DatasetComponent/BaseGalleryGridItem.vue';
import GalleryGridItemLabelTags from '@/components/DatasetComponent/GalleryComponent/GalleryGridItemLabelTags.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import BaseMenuButton from '@/components/BaseMenuButton.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: 'GalleryGridItem2',
  components: {
    BaseGalleryGridItem,
    GalleryGridItemLabelTags,
    IconButton,
    SVGIcon,
    BaseMenu,
    BaseMenuButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    imageObj: {
      type: Object,
      default: () => {},
    },
    annotations: {
      type: Array,
      default: () => [],
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    annotationDisplayType: {
      type: String,
      default: 'set',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    filterAnnotationsBySets: {
      type: Array,
      default: null,
    },
    filterAnnotationsByLabelIndexes: {
      type: Array,
      default: null,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    shouldShowSelector: {
      type: Boolean,
      default: false,
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'grid-item-clicked', 'copy', 'download', 'update:selectedImages',
  ],
  data() {
    return {
      dsConn: null,
      datastoreURL: null,
      mouseHover: false,
      renderedImageDimensions: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      show_menu: false,
      internalSelectedImages: null,
    };
  },
  computed: {
    filteredAnnotations() {
      if (this.annotations?.length > 0) {
        // Create boxes for the annotations to be displayed
        let filteredAnnotationList = this.annotations;
        if (this.filterAnnotationsBySets?.length >= 0) {
          filteredAnnotationList = filteredAnnotationList.filter((anno) => this.filterAnnotationsBySets.includes(anno.annotation_set_id));
        }
        if (this.filterAnnotationsByLabelIndexes?.length >= 0) {
          filteredAnnotationList = filteredAnnotationList.filter((anno) => this.filterAnnotationsByLabelIndexes.includes(anno.label_index));
        }
        return filteredAnnotationList;
      }
      return [];
    },
    isSequence() {
      return this.imageObj.type === 'sequence';
    },
  },
  watch: {
    selectedImages: {
      immediate: true,
      handler() {
        this.internalSelectedImages = this.selectedImages;
      },
    },
    internalSelectedImages() {
      this.$emit("update:selectedImages", this.internalSelectedImages);
    },
  },
  created() {
    this.enterpriseServerUrl = this.$store.state.enterpriseServerUrl;
    this.dsConn = new DatastoreConnect(this.enterpriseServerUrl);
  },
  methods: {
    handleImageClicked() {
      this.$emit('grid-item-clicked', this.imageObj);
    },
    copy() {
      this.$emit('copy', this.imageObj);
    },
    download() {
      this.$emit('download', this.imageObj);
    },
    async downloadImage() {
      const url = `v3/enterprise/image/${this.imageObj.id}`;
      const headers = new Headers({
        'Authorization': `Bearer ${this.$store.state.user.token}`,
      });

      try {
        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = this.imageObj.name || this.imageObj.id || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading the image:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.gallery-card-header {
  display: flex;
  justify-content: space-between;
  width: fit-content;

  .item-selector {
    position: absolute;
    left: 10px;
    z-index: 10;
  }
}

.video-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background: rgb(255 255 255 / 65%);
  border-radius: 100%;
  box-shadow: 0 0px 2px 0 rgb(0 0 0 / 50%);
  color: var(--icon-color-primary);
}

.menu-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.menu-icon {
  background: rgba(255,255,255,0.5);
  border-radius: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
}
</style>
