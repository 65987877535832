<template>
  <div class="actions-dropdown-button">
    <FilterPopper
      v-model:show="tagOpen"
      :placement="'bottom-start'"
      :offset="[-4, 5]"
    >
      <template #default="{ menuOpen }">
        <button class="button actions-toggle-button" :class="{'active': menuOpen}">
          Actions
          <SVGIcon
            :iconName="'expand_more'"
            :width="'20px'"
            :height="'20px'"
            :mirrored="menuOpen"
          />
        </button>
      </template>
      <template #popper>
        <div class="filter-settings">
          <FilterPopper
            v-model:show="updateTagOpen"
            :placement="'right-start'"
            :offset="[-4,0]"
          >
            <button
              class="filter-settings__row filter-settings__row-button"
            >
              <label :for="`action-tags`">Add & Update Tags</label>
              <SVGIcon
                class="filter-settings__row-button-chevron-right"
                :iconName="'chevron_right'"
                :height="'20px'"
                :width="'20px'"
              />
            </button>
            <template #popper>
              <div class="tags-selector scrollbar">
                <Transition name="fade" mode="out-in">
                  <div v-if="allTags">
                    <div class="chkbx-row mt-2">
                      <div
                        v-if="!newTag && newTag !== ''"
                        class="header-tag-text"
                        @click.stop.prevent="newTagClicked"
                      >
                        Click here to add a new tag
                      </div>
                      <input
                        v-if="newTag || newTag === ''"
                        :ref="`textEditorNewTag`"
                        v-model="newTag"
                        type="text"
                        class="header-tag-text"
                        @blur="createNewTag()"
                        @keyup.enter="createNewTag()"
                      >
                      <v-btn
                        v-if="newTag || newTag === ''"
                        id="send-btn"
                        :icon="'mdi-send-circle'"
                        size="x-small"
                        variant="plain"
                        class="edit-btn"
                        active-color="#250E81"
                        @click.stop.prevent="createNewTag()"
                      />
                    </div>
                    <div class="chkbx-ul scrollbar">
                      <div
                        v-for="(tag, index) in allTags"
                        :key="`tag-checkbox-${index}`"
                        class="chkbx-row"
                      >
                        <div class="check-icon me-1">
                          <SVGIcon
                            v-if="selectedTags && selectedTags.includes(tag.name)"
                            :iconName="'check'"
                            :height="'16px'"
                            :width="'16px'"
                          />
                        </div>
                        <template
                          v-if="!tag.editing"
                        >
                          <label :for="`tag-checkbox-${index}`" class="text">{{ tag.name }}</label>
                          <input
                            :id="`tag-checkbox-${index}`"
                            type="checkbox"
                            class="chkbx-input"
                            :disabled="selectedImages && selectedImages.length < 1"
                            @change.stop="($event) => handleTagsToggle($event, tag.name)"
                          >
                        </template>
                        <input
                          v-else
                          :ref="`textEditor${index}`"
                          v-model="tag.editing"
                          type="text"
                          @blur="editDone(index)"
                          @keyup.enter="editDone(index)"
                        >
                        <v-btn
                          v-if="!tag.editing"
                          :icon="'mdi-pencil'"
                          size="x-small"
                          variant="plain"
                          class="edit-btn"
                          active-color="#250E81"
                          @click.stop.prevent="edit(index)"
                        />
                        <v-btn
                          v-else
                          id="send-btn"
                          :icon="'mdi-send-circle'"
                          size="x-small"
                          variant="plain"
                          class="edit-btn"
                          active-color="#250E81"
                          @click.stop.prevent="editDone(index)"
                        />
                        <v-btn
                          :icon="'mdi-close-circle'"
                          size="x-small"
                          variant="plain"
                          class="edit-btn"
                          @click.stop.prevent="openConfirmRemoveTagsForDatasetModal(index, tag)"
                        />
                      </div>
                    </div>
                    <div
                      v-if="selectedImages && selectedImages.length < 1 && allTags && allTags.length > 0"
                      class="empty-warning"
                    >
                      Select at least 1 image to apply tags.
                    </div>
                  </div>
                  <div v-else>
                    <v-skeleton-loader
                      type="list-item"
                      theme="primary"
                      color="transparent"
                    />
                    <v-skeleton-loader
                      type="list-item"
                      theme="primary"
                      color="transparent"
                    />
                  </div>
                </Transition>
              </div>
            </template>
          </FilterPopper>
          <button
            id="delete-button"
            class="filter-settings__row filter-settings__row-button"
            :disabled="selectedImages && selectedImages.length === 0"
            @click="deleteImages"
          >
            <label>Delete Images</label>
          </button>
        </div>
      </template>
    </FilterPopper>
  </div>
  <ConfirmModal
    ref="confirmModal"
    :messageHeader="confirmMessageHeader"
    :message="confirmMessage"
    :buttonClass="'button-delete'"
    :buttonText="buttonText"
    @confirmed="confirmFunction"
  />
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ConfirmModal from '@/components/ConfirmModal.vue';

export default {
  // TODO: Refactor this action dropdown to separate tags, delete, and future actions
  name: "GalleryActionsDropdown",
  components: {
    FilterPopper,
    SVGIcon,
    ConfirmModal,
  },
  props: {
    selectedImages: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'delete-images', 'refresh-dataset', 'add-tag', 'remove-tag',
  ],
  data() {
    return {
      tagOpen: false,
      updateTagOpen: false,
      allTags: null,
      selectedTags: null,
      newTag: null,
      dataConnect: new DatastoreConnect(),
      buttonText: '',
      confirmMessage: null,
      confirmMessageHeader: null,
      confirmFunction: null,
      toDelete: null,
    };
  },
  computed: {
  },
  watch: {
    tagOpen(isOpen) {
      if (isOpen) {
        this.refreshDatasetTags().then(() => this.refreshImagesTags());
      } else {
        this.selectedTags = null;
      }
    },
  },
  methods: {
    async refreshDatasetTags() {
      const dataConnect = new DatastoreConnect();
      await dataConnect.listTagsForDataset({
        dataset_id: this.dataset.id,
      })
        .then((resp) => {
          if (resp.error) {
            throw resp.error;
          }
          this.allTags = resp.result;
          this.allTags = this.allTags.map((tag) => ({
            ...tag,
            editing: null,
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async refreshImagesTags() {
      const dataConnect = new DatastoreConnect();
      if (this.selectedImages && this.selectedImages.length > 0) {
        const image_ids = this.selectedImages.map((e) => e.id);

        await dataConnect.listTagsForImages({
          dataset_id: this.dataset.id,
          image_ids,
        })
          .then((resp) => {
            if (resp.error) {
              throw resp.error;
            }
            this.selectedTags = resp.result.map((e) => e.name);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    edit(index) {
      this.allTags[index].editing = this.allTags[index].name;
      this.$nextTick(() => {
        this.$refs[`textEditor${index}`][0].focus();
      });
    },
    openConfirmRemoveTagsForDatasetModal(index, tag) {
      this.confirmMessage = `Are you sure you want to remove tag "${tag.name}" from this dataset? This will remove it from all images where it is applied.`;
      this.confirmMessageHeader = 'Remove Tag';
      this.toDelete = tag;
      this.buttonText = 'Remove';
      this.$refs.confirmModal.showModal();
      this.confirmFunction = () => {
        this.removeTagForDataset(index);
      };
    },
    removeTagForDataset(index) {
      this.dataConnect.removeTagForDataset({
        dataset_id: this.dataset.id,
        tag_name: this.allTags[index].name,
      })
        .then((resp) => {
          if (resp.error) {
            throw resp.error;
          }
          // this.allTags = this.allTags.filter((e) => e.name !== this.allTags[index].name);
          this.refreshDatasetTags().then(() => {
            this.$emit('refresh-dataset');
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editDone(index) {
      if (this.allTags[index].editing && this.allTags[index].editing !== '') {
        this.dataConnect.updateTagName({
          dataset_id: this.dataset.id,
          old_name: this.allTags[index].name,
          tag_name: this.allTags[index].editing,
        })
          .then((resp) => {
            if (resp.error) {
              throw resp.error;
            }
            this.allTags[index].name = this.allTags[index].editing;
            this.refreshDatasetTags().then(() => this.refreshImagesTags().then(() => {
              this.$emit('refresh-dataset');
            }));
          })
          .catch((error) => {
            console.error(error);
          });
      }
      this.allTags[index].editing = null;
    },
    async removeTagsForImages(tag_name) {
      if (this.selectedImages && this.selectedImages.length > 0) {
        // remove for selected images
        const image_ids = this.selectedImages.map((e) => e.id);
        await this.dataConnect.removeTagForImages({
          dataset_id: this.dataset.id,
          image_ids,
          tag_names: [tag_name],
        })
          .then((resp) => {
            if (resp.error) {
              throw resp.error;
            }
            // this.allTags = this.allTags.filter((e) => e.name !== this.allTags[index].name);
            this.refreshImagesTags().then(() => {
              this.$emit('remove-tag', tag_name);
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async addTagsForImages(tag_name) {
      if (this.selectedImages && this.selectedImages.length > 0) {
        // add tags for selected images
        const image_ids = this.selectedImages.map((e) => e.id);
        await this.dataConnect.addNewTagForImages({
          dataset_id: this.dataset.id,
          tag_names: [tag_name],
          image_ids,
        })
          .then(async (resp) => {
            if (resp.error) {
              throw resp.error;
            }
            await this.refreshImagesTags().then(() => {
              this.$emit('add-tag', tag_name);
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    newTagClicked() {
      this.newTag = '';
      this.$nextTick(() => { this.$refs.textEditorNewTag.focus(); });
    },
    handleTagsToggle(event, tag_name) {
      if (!this.selectedTags.includes(tag_name)) {
        this.addTagsForImages(tag_name);
      } else {
        this.removeTagsForImages(tag_name);
      }
    },
    createNewTag() {
      if (this.newTag && this.newTag !== '') {
        // add tags for dataset
        this.dataConnect.addNewTagForDataset({ dataset_id: this.dataset.id, tag_name: this.newTag })
          .then(async (resp) => {
            if (resp.error) {
              throw resp.error;
            }
            this.allTags.push({ name: this.newTag });
            if (this.selectedImages && this.selectedImages.length > 0) {
              await this.addTagsForImages(this.newTag);
            } else {
              this.refreshDatasetTags();
            }
            this.newTag = null;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.newTag = null;
      }
    },
    deleteImages() {
      this.$emit('delete-images');
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 0 6px;
  height: 18px;
  @include themify() {
    background: themed('color-accent');
  }
}

.filter-settings {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  text-transform: none;
  min-width: 100px;

  &.scrollbar {
    overflow: auto;
  }

  &__row {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 600px;
  }

  &__row-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    padding: 6px 8px;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    &:last-of-type {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:hover {
      background: rgba(0,0,0,0.125);
    }
    &:focus-within {
      background: rgba(0,0,0,0.125);
    }

    &> * {
      pointer-events: none;
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      opacity: 0;
      margin: 0;
    }

    span, label {
      font-size: 0.9rem;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__row-button-check {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    @include themify() {
      color: themed('filter-button-list-row-button-selected')
    }
  }

  &__row-button-legend {
    min-width: 10px;
    min-height: 10px;
    margin-right: 12px;
    border-radius: 100%;
  }

  &__row-button-chevron-right {
    margin-left: auto;
    padding-left: 4px;
  }
}

.actions-toggle-button, .settings-toggle-button {
  background: white;

}

.actions-dropdown-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding: 0;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2), 0 0 1px 1px rgba(0, 0, 0, 1);
  @include themify() {
    color: themed('body-text-color')
  }
}

.actions-toggle-button {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
  padding: 4px 8px;
  gap: 2px;
  border-right: solid 1px rgba(0,0,0,0.5);
  color: inherit;

  &:hover {
    box-shadow: 0 0 2px 1px rgba(var(--color-primary-rgb), 0.2) inset;
    background: rgba(var(--color-primary-100-rgb), 0.5);
  }

  &:active {
    opacity: 1;
  }

  &.active {
    @include themify() {
      box-shadow: 0 0 2px 1px rgba(var(--color-primary-rgb), 0.5) inset;
      background: rgba(var(--color-primary-100-rgb), 0.8);
    }
  }
}

.tags-selector {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-radius: inherit;
  gap: 6px;
  min-width: 250px;
  max-height: 500px;

  &__row {
    display: flex;
    align-items: center;

    input[type=checkbox] {
      margin-right: 12px;
    }

    label {
      font-size: 0.9rem;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .sub-menu-header {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    font-size: 0.9rem;
    color: var(--gray-900);

    .title {
      font-weight: 600;
      padding: 0 5px 0 3px;
      margin-left: 7px;
    }

    .tags-grid {
      display: grid;
      max-height: 170px;
      overflow-y: auto;
      gap: 6px;
      margin: 0 5px 0 7px;
    }
  }

  .chkbx-ul {
    flex: 1 1 auto;
    max-height: 250px;
    overflow-y: auto;
  }

  .chkbx-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    min-height: 25px;
    padding: 0 5px 0 3px;

    &:hover, &._active {
      background: rgba(0,0,0,0.1);
    }

    &:hover .edit-btn {
        visibility: visible !important;
      }

    .checkbox {
      width: 28px;
      max-width: 28px;
    }

    .check-icon {
      color: #250E81;
      width: 16px;
      min-width: 16px;
      height: 16px;
      min-height: 16px;
      }

    .text {
      margin-left: 5px;
      font-size: 0.9rem;
      width: 170px;
      border-bottom: 1px solid transparent;
      cursor: default;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .chkbx-input {
        display: none;
    }

    .header-tag-text {
      margin-left: 5px;
      font-size: 0.9rem;
      width: 170px;
      border-bottom: 1px solid transparent;
      cursor: default;
      flex: 1 1 auto;
      font-weight: 600;
      color: var(--gray-900);
    }

    input[type="text"] {
      margin-left: 5px;
      width: 170px;
      font-size: 0.9rem;
      border-bottom: 1px solid transparent;
      outline: none;

      &:focus {
        border-bottom: 1px solid #250E81;
      }
    }

    .edit-btn:not(#send-btn) {
      visibility: hidden;
    }
  }

  .empty-warning {
    flex: 1 1 auto;
    height: fit-content;
    padding: 0 5px 0 3px;
    margin-left: 5px;
    font-size: 0.9rem;
    color: var(--gray-600);
  }

  .add-tags-btn {
    flex: 1 1 auto;
    height: fit-content;
    padding: 0 5px 0 3px;
    margin-left: 5px;
    display: flex;
    justify-content: flex-end;
  }
}

#delete-button {
  label {
    color: var(--color-red-500);
    font-weight: 600;
  }

  &:disabled {
    label {
      color: var(--color-disabled-200) !important;
    }
    cursor: not-allowed;
  }
}
</style>
