<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Image Name'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="settings__control-group scrollbar">
        <input
          v-model="value"
          class="select"
          type="text"
          placeholder="'cat' or 'cat.jpg'"
        >
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import * as utils from '@/assets/js/utils.js';

export default {
  name: "ImageNameFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      value: '',
    };
  },
  computed: {
    isActive() {
      return this.value.length > 0;
    },
    tagValue() {
      return this.value;
    },
  },
  watch: {
    value() {
      this.debouncedUpdateFilters();
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  created() {
    this.debouncedUpdateFilters = utils.debounce(this.updateFilters, 600);
  },
  mounted() {
    this.value = this.modelValue;
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.value);
    },
    clearSelection() {
      this.value = '';
    },
  },
};
</script>

<style lang="scss" scoped>

input[type="text"] {
  width: 200px;
  margin: 15px;
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}
</style>
