<template>
  <div id="gallery-component-container" class="gallery">
    <template v-if="!doneFetch">
      <slot name="overlay" />
    </template>
    <div v-if="doneFetch && imageList && imageList.length == 0" class="empty-state">
      <slot name="empty-state" />
    </div>
    <template v-if="imageList && imageList.length > 0">
      <div v-if="showHeader" class="gallery__header">
        <div class="gallery__header-row">
          <div class="header-slot _start">
            <slot name="header-first-row-start" />
          </div>
          <div class="header-slot _end">
            <slot name="header-first-row-end" />
          </div>
        </div>
        <div class="gallery__header-row">
          <div class="header-slot _start">
            <slot name="header-second-row-start" />
          </div>
          <div class="header-slot _end">
            <slot name="header-second-row-end" />
          </div>
        </div>
      </div>
      <div
        v-if="doneFetch"
        ref="gridContainer"
        class="gallery__grid-container"
        :style="gridContainerStyles"
      >
        <div
          v-if="!hasGridItemSlot"
          id="gallery"
          ref="gallery"
          class="gallery__grid scrollbar"
          @scroll="handleScroll"
        >
          <GalleryGridItem
            v-for="imageObj in imageList"
            :key="imageObj.id"
            :imageObj="imageObj"
            :annotationSets="annotationSets"
            @image-deleted="$emit('image-deleted')"
            @grid-item-clicked="handleImageClicked(imageObj)"
          />
          <slot name="grid-item" />
        </div>
        <div
          v-else
          id="gallery"
          ref="gallery"
          class="gallery__grid scrollbar"
          :style="gridItemStyles"
          @scroll="handleScroll"
        >
          <slot name="grid-item" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import {
  ref, computed,
} from 'vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import GalleryGridItem from './GalleryGridItem.vue';

export default {
  name: 'GalleryGrid',
  components: {
    GalleryGridItem,
  },
  props: {
    doneFetch: {
      type: Boolean,
      default: true,
    },
    imageList: {
      type: Array,
      default: () => [],
    },
    selectedImages: {
      type: Array,
      default: () => [],
    },
    totalImageCount: {
      type: Number,
      default: 0,
    },
    imagesPerPage: {
      type: Number,
      default: 40,
    },
    annotationSets: {
      type: Array,
      default: () => [],
    },
    gridItemScale: {
      type: Number,
      default: null,
    },
    minGridItemHeight: {
      type: Number,
      default: 250,
    },
    minGridItemWidth: {
      type: Number,
      default: 250,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:page', 'update:totalPages', 'grid-item-clicked', 'image-deleted', 'scrolled-to-end', 'update:selectedImages',
  ],
  setup(_, { slots }) {
    const hasGridItemSlot = computed(() => slots['grid-item'] && slots['grid-item']());
    const gridContainer = ref(null);

    return {
      gridContainer,
      hasGridItemSlot,
    };
  },
  data() {
    return {
      scrollTop: 0,
      imageOffset: -1,
      imageLimit: 40,
    };
  },
  computed: {
    gridItemStyles() {
      let style;
      if (this.gridItemScale < 1) {
        style = {
          '--grid-item-width': `${this.minGridItemWidth + (this.gridItemScale * this.minGridItemWidth)}px`,
          // '--grid-item-height': `${this.minGridItemHeight}px`,
        };
      }
      if (this.gridItemScale === 1) {
        style = {
          '--grid-item-width': `100%`,
          // '--grid-item-height': `100%`,
        };
      }
      return style;
    },
    gridContainerStyles() {
      if (this.gridItemScale === 1) {
        return {
          'justify-content': 'center',
        };
      }
      return null;
    },
  },
  watch: {
    selectedImages: {
      deep: true,
      handler() {
        if (this.selectedImages && this.selectedImages.length > 0) {
          const selectedIDs = this.selectedImages.map((e) => e.id);
          this.selectedItemsSet = new Set(selectedIDs);
        } else {
          this.selectedItemsSet = new Set([]);
        }
      },
    },
    // selectedItemsSet: {
    //   handler() {
    //     this.$emit('update:selectedImages', this.imageList.filter((e) => this.selectedItemsSet.has(e.id)).map((e) => e.id));
    //   },
    // },
  },
  created() {
    this.dsConn = new DatastoreConnect(this.$store.state.enterpriseServerUrl);
  },

  methods: {
    handleImageClicked(imageObj) {
      this.$emit('grid-item-clicked', imageObj);
    },
  },
};
</script>

<style>
  #gallery-page-selector li button {
    padding: 1px 6px;
  }
</style>

<style lang="scss" scoped>

.gallery-image-count {
  margin-left: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  @include themify() {
    color: themed('body-text-color-secondary');
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__header-row {
    display: flex;
    margin: 20px 20px 0 20px;

    .header-slot:first-of-type {
      justify-content: flex-start;
    }
    .header-slot:not(:first-of-type) {
      justify-content: flex-end;
    }
  }

  &__grid-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
  }

  &__grid {
    --grid-item-width: 250px;
    // --grid-item-height: 250px;
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--grid-item-width));
    // grid-auto-rows: var(--grid-item-height);
    grid-gap: 20px 20px;
    // height: auto;
    padding: 0 20px 15px 20px;
    margin: 20px 0 20px 0;
    overflow: auto;
    transition: all 0.5s;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.header-slot {
  display:flex;

  &._start {
    justify-content: flex-start;
    flex: 1 1 30%;
  }

  &._end {
    justify-content: flex-end;
    flex: 1 1 60%;
  }
}

.pagination {
  width: 400px;
  max-width: 400px;
  height: fit-content;
}

:deep {
  .v-pagination__list {
    justify-content: flex-end;
  }
}

:deep(.v-pagination__item) {
min-width: fit-content;
}

:deep(.v-pagination__item) > button {
width: fit-content !important;
min-width: fit-content;
min-height: fit-content;
padding: 9px;
}

:deep(.v-pagination__prev) > button,
:deep(.v-pagination__next) > button {
width: fit-content !important;
min-width: fit-content;
min-height: fit-content;
padding: 8px 6px;
}

:deep(.v-btn)[ellipsis="true"] > .v-btn__content {
color: rgba(0, 0, 0, 0.89) !important;
}

</style>
