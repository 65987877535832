<template>
  <FilterPopper v-model:show="isOpen" :appendToBody="true">
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Date Range'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <Transition name="fade" mode="out-in">
          <div v-if="initialized" class="date-container">
            <v-date-picker
              v-model="startDate"
              :max="maxDateFormatted"
              :min="minDateFormatted"
              title="Select Start Date"
              elevation="3"
              color="#250E81"
            />
            <v-date-picker
              v-model="endDate"
              :max="maxDateFormatted"
              :min="minDateFormatted"
              title="Select End Date"
              elevation="3"
              color="#250E81"
            />
          </div>
          <div v-else class="date-range-loader">
            <v-skeleton-loader
              type="date-picker-days"
              theme="primary"
              color="transparent"
            />
            <v-skeleton-loader
              type="date-picker-days"
              theme="primary"
              color="transparent"
            />
          </div>
        </Transition>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import * as utils from '@/assets/js/utils.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "DateRangeFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      isOpen: false,
      startDate: null,
      endDate: null,
      minDate: null,
      maxDate: null,
      initialized: false,
    };
  },

  computed: {
    currentYear() {
      const currentDate = new Date();
      return currentDate.getFullYear();
    },
    isActive() {
      if (!this.startDate || !this.endDate || !this.minDate || !this.maxDate) {
        return false;
      }
      // Ensure that startDate and endDate are not null
      const startDateValid = this.startDate !== null;
      const endDateValid = this.endDate !== null;

      // Check if startDate and endDate are different from minDate and maxDate
      const startDateDifferent = startDateValid && this.startDate.getTime() !== this.minDate.getTime();
      const endDateDifferent = endDateValid && this.endDate.getTime() !== this.maxDate.getTime();

      // Return true if both startDate and endDate are set and different from minDate and maxDate
      return (startDateValid || endDateValid) && (startDateDifferent || endDateDifferent);
    },
    tagValue() {
      let startDate = '';
      let endDate = '';
      if (this.startDate) {
        startDate = new Date(this.startDate);
        startDate = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
      }

      if (this.endDate) {
        endDate = new Date(this.endDate);
        endDate = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
      }

      if (this.startDate || this.endDate) {
        return `${startDate} - ${endDate}`;
      }
      return '';
    },
    minDateFormatted() {
      if (!this.minDate) return undefined;
      const year = this.minDate.getUTCFullYear();
      const month = String(this.minDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(this.minDate.getUTCDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
    maxDateFormatted() {
      if (!this.maxDate) return undefined;
      const year = this.maxDate.getUTCFullYear();
      const month = String(this.maxDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(this.maxDate.getUTCDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },
  },
  watch: {
    startDate() {
      this.debouncedUpdateFilters();
    },
    endDate() {
      this.debouncedUpdateFilters();
    },
    isOpen(isOpen) {
      if (isOpen) {
        const dataConnect = new DatastoreConnect();
        dataConnect.getDatasetDateRange({ dataset_id: this.dataset.id })
          .then((resp) => {
            this.initialized = true;
            if (resp.error) {
              throw resp.error;
            }
            if (resp.result) {
              this.minDate = new Date(resp.result.min_date);
              this.maxDate = new Date(resp.result.max_date);

              // Set time parts to 00:00:00
              this.minDate.setHours(0, 0, 0, 0);
              this.maxDate.setHours(0, 0, 0, 0);

              if (!this.startDate) {
                this.startDate = this.minDate;
              }
              if (!this.endDate) {
                this.endDate = this.maxDate;
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    modelValue(newTime, oldTime) {
      if (oldTime && newTime) {
        const oldStartTime = new Date(oldTime[0]);
        const oldEndTime = new Date(oldTime[1]);
        const newStartTime = new Date(newTime[0]);
        const newEndTime = new Date(newTime[1]);

        if (oldStartTime.getTime() !== newStartTime.getTime() && oldEndTime.getTime() !== newEndTime.getTime()) {
          this.startDate = new Date(newTime[0]);
          this.endDate = new Date(newTime[1]);
        }
      } else if (!oldTime && newTime) {
        this.startDate = new Date(newTime[0]);
        this.endDate = new Date(newTime[1]);
      }
    },
  },
  created() {
    this.debouncedUpdateFilters = utils.debounce(this.updateFilters, 600);
    this.minDate = new Date(1990, 0, 1, 0, 0, 0, 0);
    this.maxDate = new Date(this.currentYear, 0, 1, 0, 0, 0, 0);
  },
  methods: {
    updateFilters() {
      if (!this.isActive) {
        this.$emit('update:modelValue', null);
        return;
      }
      if (this.startDate || this.endDate) {
        this.$emit('update:modelValue', [
          `${this.startDate ? this.startDate : ''}`,
          `${this.endDate ? this.endDate : ''}`,
        ]);
        return;
      }
      this.$emit('update:modelValue', undefined);
    },
    clearSelection() {
      if (this.minDate) {
        this.startDate = this.minDate;
      } else {
        this.startDate = null;
      }

      if (this.maxDate) {
        this.endDate = this.maxDate;
      } else {
        this.endDate = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

:deep{
  .filter-tag {
    max-width: 152px !important;
  }
}

.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: inherit;
  gap: 16px;
  min-height: 50px;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & span {
    width: 40px;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

.date-container {
  display: flex;
  flex-direction: row;
  min-width: 500px;
  gap: 16px;
}

.date-range-loader {
  display: flex;
  flex-direction: row;
  min-width: 668px;
  gap: 16px;

}
</style>
