<template>
  <FilterPopper :appendToBody="true">
    <IconButton
      class="annset-info-icon"
      :icon="'info'"
      :width="20"
      :height="20"
      :type="''"
      @click.stop=""
    />
    <template #popper>
      <div v-if="labels && labels.length > 0" class="label-li-container">
        <div class="space-between">
          <span class="container-header">
            All labels
            <IconButton
              id="copy-labels-button"
              :icon="'copy_content'"
              :width="18"
              :height="18"
              :title="`Copy Labels to Clipboard`"
              @click.stop="copyLabels"
            />
          </span>
          <IconButton
            :icon="'add'"
            :width="18"
            :height="18"
            :title="`Add New Label`"
            @click.stop="newLabel"
          />
        </div>
        <div class="table-scroll scrollbar">
          <table class="table">
            <colgroup>
              <col span="1" style="width: 30%;">
              <col span="1" style="width: 70%;">
            </colgroup>
            <tbody>
              <tr>
                <th><h4>Color</h4></th>
                <th><h4>Index</h4></th>
                <th><h4>Name</h4></th>
              </tr>
              <tr v-for="(l,i) in internalLabels" :key="i">
                <td>
                  <!-- Do not append to body here, as it will propagate the click event to the parent popper -->
                  <ColorPicker
                    :color="l.color"
                    :appendToBody="false"
                    :width="50"
                    :height="20"
                    :radius="8"
                    :offset="[-10, 20]"
                    @new-color="(color) => stopEditingColor(i, l, color)"
                  />
                </td>
                <td>
                  <div class="label-index">
                    <span v-show="!internalLabels[i].editingIndex" @click="startEditingIndex($event, i)">{{ l.index }}</span>
                    <input
                      v-show="internalLabels[i].editingIndex"
                      v-model="l.newIndex"
                      type="number"
                      min="0"
                      step="1"
                      class="text-input"
                      @blur.stop="stopEditingIndex(i, l)"
                      @keyup.enter.stop="unfocus($event)"
                    >
                  </div>
                </td>
                <td>
                  <div class="label-name">
                    <span v-show="!internalLabels[i].editingName" @click="startEditingName($event, i)">{{ l.name }}</span>
                    <input
                      v-show="internalLabels[i].editingName"
                      v-model="l.newName"
                      type="text"
                      class="text-input"
                      @blur.stop="stopEditingName(i, l)"
                      @keyup.enter.stop="unfocus($event)"
                    >
                    <IconButton
                      v-if="canWrite"
                      class="delete-label-icon"
                      :icon="'close'"
                      :width="18"
                      :height="18"
                      :title="`Delete Label ${l.name}`"
                      :type="''"
                      @click.stop="deleteLabel(l)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="label-li-container scrollbar">
        <span class="container-header">This dataset does not have any label.</span>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import IconButton from '@/components/IconButton.vue';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';
import ColorPicker from '@/components/ColorPicker.vue';

export default {
  name: "DatasetCardLabelsPopper",
  components: {
    FilterPopper,
    IconButton,
    ColorPicker,
  },
  props: {
    dataset: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'delete-label', 'update-label', 'label-created',
  ],
  data() {
    return {
      internalLabels: [],
      dsConn: new DatastoreConnect(this.$store.state.enterpriseServerUrl),
    };
  },
  computed: {
    isDebugMode() {
      return this.$store.state.debugMode;
    },
    canWrite() {
      return this.permissions && this.permissions.dataset_write;
    },
    existingIndices() {
      return this.labels.map((e) => e.index);
    },
    existingNames() {
      return this.labels.map((e) => e.name);
    },
  },
  watch: {
    labels: {
      deep: true,
      immediate: true,
      handler() {
        if (this.labels) {
          this.internalLabels = JSON.parse(JSON.stringify(this.labels));
          this.internalLabels.forEach((label, i) => {
            this.internalLabels[i].editingIndex = false;
            this.internalLabels[i].newIndex = label.index;
            this.internalLabels[i].editingName = false;
            this.internalLabels[i].newName = label.name;
            this.internalLabels[i].editingName = false;
            this.internalLabels[i].newColor = label.color;
          });
        } else {
          this.internalLabels = [];
        }
      },
    },
  },
  methods: {
    deleteLabel(l) {
      this.$emit('delete-label', l);
    },
    async newLabel() {
      await this.dsConn.addLabel({
        dataset_id: this.dataset.id,
        label_names: ['NewLabel'],
      })
        .then((data) => {
          if (!data.error) {
            this.$emit('label-created');
          } else {
            console.log(data.error);
          }
        })
        .catch((error) => {
          // TODO: handle error
          console.log(error);
        });
    },
    copyLabels() {
      // Clipboard is not available on unsecure connections. Copy from alert instead.
      const labelString = JSON.stringify(this.labels.map((l) => ({
        name: l.name,
        color: l.color,
      })));
      if (navigator.clipboard) {
        navigator.clipboard.writeText(labelString);
      } else {
        alert(labelString);
      }
    },
    startEditingIndex(event, i) {
      if (!this.canWrite) {
        return;
      }
      this.internalLabels[i].editingIndex = true;
      this.$nextTick(() => {
        const inputField = event.target.nextElementSibling;
        if (inputField) {
          inputField.focus();
        }
      });
    },
    async stopEditingIndex(i, label) {
      if (!this.canWrite) {
        return;
      }
      if (this.existingIndices.includes(label.newIndex)) {
        label.newIndex = label.index;
        this.internalLabels[i].editingIndex = false;
      } else {
        const params = {
          dataset_id: this.dataset.id,
          label_id: label.id,
          label_index: label.newIndex,
          label_name: label.name,
          label_color: label.color,
        };
        await this.dsConn.updateLabel(params)
          .then((data) => {
            if (!data.error) {
              this.internalLabels[i].editingIndex = false;
              this.$emit('update-label');
            } else {
              label.newIndex = label.index;
            }
          })
          .catch((e) => console.log(e));
      }
    },
    startEditingName(event, i) {
      if (!this.canWrite) {
        return;
      }
      this.internalLabels[i].editingName = true;
      this.$nextTick(() => {
        const inputField = event.target.nextElementSibling;
        if (inputField) {
          inputField.focus();
        }
      });
    },
    async stopEditingName(i, label) {
      if (!this.canWrite) {
        return;
      }
      if (!label.newName || label.newName === '') {
        label.newName = label.name;
        this.internalLabels[i].editingName = false;
        return;
      }
      if (this.existingNames.includes(label.newName)) {
        label.newName = label.name;
        this.internalLabels[i].editingName = false;
      } else {
        const params = {
          dataset_id: this.dataset.id,
          label_id: label.id,
          label_index: label.index,
          label_name: label.newName,
          label_color: label.color,
        };
        await this.dsConn.updateLabel(params)
          .then((data) => {
            if (!data.error) {
              this.internalLabels[i].editingName = false;
              this.$emit('update-label');
            } else {
              label.newName = label.name;
            }
          })
          .catch((e) => console.log(e));
      }
    },
    startEditingColor(event, i) {
      if (!this.canWrite) {
        return;
      }
      this.internalLabels[i].editingColor = true;
      this.$nextTick(() => {
        const inputField = event.target.nextElementSibling;
        if (inputField) {
          inputField.focus();
        }
      });
    },
    async stopEditingColor(i, label, color) {
      if (!this.canWrite) {
        return;
      }

      if (!color || color === '') {
        this.internalLabels[i].editingColor = false;
        return;
      }

      const params = {
        dataset_id: this.dataset.id,
        label_id: label.id,
        label_index: label.index,
        label_name: label.name,
        label_color: color,
      };
      await this.dsConn.updateLabel(params)
        .then((data) => {
          if (!data.error) {
            this.internalLabels[i].editingColor = false;
            this.$emit('update-label');
          }
        })
        .catch((e) => console.log(e));
    },
    unfocus(event) {
      this.$nextTick(() => {
        const inputField = event.target;
        if (inputField) {
          inputField.blur();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-li-container {
  min-width: 360px;
  max-width: 360px;
  width: 360px;
  padding: 0 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}
.space-between{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.container-header {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: bold;
  padding: 2px;
}

#copy-labels-button {
  margin-left: 8px;
  cursor: copy;
}

.table-scroll {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.table {
  text-align: left;
  padding:  0 10px;
  border: 0px;
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 0 5px;
  }

  td {
    padding: 0 5px;

    span {
      display:inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .text-input {
      border: 1px solid #596073;
      font-size: 16px;
      padding: 0 !important;
      padding-left: 5px !important;
      margin: 0;
      border-radius: 6px;
      background: field;
      font-style: italic;

      &:focus {
        border: 1px solid #250E81;
        box-shadow: 0 0 0 1px #250E81, 0 0 3px #7c6eb3;
      }
    }

    .label-index {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 80px;
      width: 80px;
      span, input {
        width: 100%;
        cursor:text
      }
    }

    .label-name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      max-width: 140px;
      width: 140px;
      span, input {
        width: 105px;
        cursor:text
      }
    }

    .delete-label-icon{
      visibility: hidden;
    }
  }

  td:hover {
    .delete-label-icon{
      visibility: visible;
    }
  }

  tr {
   height: 30px;
   padding: 5px;
  }

  tr:hover {
    border-radius: 8px;
    background: var(--color-white-700);
  }
}
</style>
