import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useGalleryStore } from '@/stores/useGalleryStore.js';
import DatastoreConnect from '@/assets/js/DatastoreFunctions/datastore-interface';

export default function useGallery() {
  const controller = ref(new AbortController());

  const {
    datasetFilters,
    annotationDisplaySettings,
    imageDisplaySettings,
    reverse,
    currentDatasetID,
    internalCurrentDataset,
    sortBy,
    currentPage,
    totalPages,
    imagesPerPage,
    imageList,
    totalImageCount,
    executing,
    combineSequenceFrames,
    isFetchingImages,
  } = storeToRefs(useGalleryStore());

  const {
    setImageParams,
    setDatasetFilters,
    $reset,
  } = useGalleryStore();

  function getImagesParams() {
    const params = {
      get_annotations: true,
      get_tags: true,
      combine_sequence_frames: combineSequenceFrames.value,
      sort_by: sortBy.value,
      reverse: reverse.value,
      offset: (currentPage.value - 1) * imagesPerPage.value,
      limit: imagesPerPage.value,
    };

    return params;
  }

  function getImagesFilterParams() {
    const params = {
      images_filter: { dataset_id: currentDatasetID.value },
      image_files_filter: {},
      annotations_filter: {},
      image_tags_filter: {},
    };

    // Images filter
    if (datasetFilters.value.groupsFilter && datasetFilters.value.groupsFilter.length > 0) {
      params.images_filter.group_ids = datasetFilters.value.groupsFilter;
    }

    // Annotations filter
    if (datasetFilters.value.confidenceRangeFilter) {
      params.annotations_filter.lower_score = datasetFilters.value.confidenceRangeFilter[0];
      params.annotations_filter.upper_score = datasetFilters.value.confidenceRangeFilter[1];
    }
    if (datasetFilters.value.annotationTypeFilter && datasetFilters.value.annotationTypeFilter.length > 0) {
      params.annotations_filter.types = datasetFilters.value.annotationTypeFilter;

      if (datasetFilters.value.annotationTypeFilterInvert) {
        params.annotations_filter.types_inverse = true;
      }
    }
    if (datasetFilters.value.annotationSetFilter && datasetFilters.value.annotationSetFilter.length > 0) {
      params.annotations_filter.annotation_set_ids = datasetFilters.value.annotationSetFilter.map((set) => set.id);
    }
    if (datasetFilters.value.imagesWithAnnotationSetFilter && datasetFilters.value.imagesWithAnnotationSetFilter.length > 0) {
      params.annotations_filter.images_with_annotation_set_ids = datasetFilters.value.imagesWithAnnotationSetFilter.map((set) => set.id);
    }
    if (datasetFilters.value.annotationLabelFilter && datasetFilters.value.annotationLabelFilter.length > 0) {
      params.annotations_filter.images_with_annotation_label_indexes = datasetFilters.value.annotationLabelFilter;
    }
    if (datasetFilters.value.boundingBoxSizeFilter) {
      Object.entries(datasetFilters.value.boundingBoxSizeFilter).forEach(([key, value]) => {
        if (key !== 'type' && value) {
          params.annotations_filter[`${key}_${datasetFilters.value.boundingBoxSizeFilter.type}`] = value;
        }
      });
    }

    // Image files filter
    if (datasetFilters.value.imageNameFilter && datasetFilters.value.imageNameFilter.length > 0) {
      params.image_files_filter.image_name = datasetFilters.value.imageNameFilter;
    }
    if (datasetFilters.value.timeRangeFilter) {
      params.image_files_filter.lower_time = datasetFilters.value.timeRangeFilter[0];
      params.image_files_filter.upper_time = datasetFilters.value.timeRangeFilter[1];
    }
    if (datasetFilters.value.dateRangeFilter) {
      if (datasetFilters.value.dateRangeFilter[0]) {
        params.image_files_filter.lower_date = new Date(datasetFilters.value.dateRangeFilter[0]).toISOString();
      }
      if (datasetFilters.value.dateRangeFilter[1]) {
        params.image_files_filter.upper_date = new Date(datasetFilters.value.dateRangeFilter[1]).toISOString();
      }
    }
    if (datasetFilters.value.cameraSourceFilter && datasetFilters.value.cameraSourceFilter.length > 0) {
      params.image_files_filter.image_sources = datasetFilters.value.cameraSourceFilter;
    }

    // Tags
    if (datasetFilters.value.imageTagFilter && datasetFilters.value.imageTagFilter.length > 0) {
      params.image_tags_filter.tag_names = datasetFilters.value.imageTagFilter.map((tag) => tag.name);
    }

    if (Object.keys(params.annotations_filter).length === 0) {
      delete params.annotations_filter;
    }

    if (Object.keys(params.image_tags_filter).length === 0) {
      delete params.image_tags_filter;
    }

    return params;
  }

  async function getSelectionImageCount(filterParams) {
    const dataConnect = new DatastoreConnect();
    const resp = await dataConnect.getFilteredImages({
      only_count: true,
      combine_sequence_frames: getImagesParams().combine_sequence_frames,
      ...filterParams,
    })
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        console.error(error);
        return 0;
      });

    return resp;
  }

  async function getImages() {
    if (currentDatasetID.value === 0) {
      totalImageCount.value = 0;
      return [];
    }

    if (internalCurrentDataset.value && internalCurrentDataset.value.parked_at !== "0001-01-01T00:00:00Z") {
      return [];
    }

    isFetchingImages.value = true;
    const dataConnect = new DatastoreConnect();
    controller.value.abort();
    controller.value = new AbortController();

    totalImageCount.value = await getSelectionImageCount(getImagesFilterParams(), controller.value.signal);
    if (totalImageCount.value === 0) {
      isFetchingImages.value = false;
      return [];
    }

    // Handle case when currentPage is greater than the new total number of pages
    if (Math.ceil(totalImageCount.value / imagesPerPage.value) < currentPage.value) {
      let newPage = Math.ceil(totalImageCount.value / imagesPerPage.value);
      if (newPage < 1) {
        newPage = 1;
      }
      currentPage.value = newPage;
      return imageList.value;
    }

    const imageListParams = {
      ...getImagesParams(),
      ...getImagesFilterParams(),
    };
    const resp = await dataConnect.getFilteredImages(imageListParams, controller.value.signal)
      .then((resp) => {
        if (resp.error !== undefined) {
          throw Error(resp.error.message);
        }
        return resp.result;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });

    isFetchingImages.value = false;

    return resp;
  }

  async function deleteFilteredImages() {
    if (currentDatasetID.value === 0) {
      totalImageCount.value = 0;
      return [];
    }

    if (internalCurrentDataset.value && internalCurrentDataset.value.parked_at !== "0001-01-01T00:00:00Z") {
      return [];
    }

    const dataConnect = new DatastoreConnect();
    controller.value.abort();
    controller.value = new AbortController();

    const resp = await dataConnect.deleteFilteredImages({
      ...getImagesParams(),
      ...getImagesFilterParams(),
    });
    if (!resp || resp.error || !resp.result) {
      // TODO: handle error
      return 0;
    }

    return resp.result;
  }

  async function handleGetPreviousImage(currentImageObj) {
    executing.value = true;
    let resultImageObj = currentImageObj;
    const index = imageList.value.findIndex((img) => img.id === currentImageObj.id);
    if ((index - 1) >= 0) {
      const previousImageObj = imageList.value[(index - 1)];
      resultImageObj = previousImageObj;
    } else if (currentPage.value > 1) {
      currentPage.value -= 1;
      // Await new images before setting previous image
      const promise = new Promise((resolve, reject) => {
        getImages().then((list) => {
          imageList.value = list;
          resolve(list);
        });
      });
      await promise.then((list) => {
        const nextImageObj = list[imagesPerPage.value - 1];
        resultImageObj = nextImageObj;
      });
    }
    executing.value = false;
    return resultImageObj;
  }

  async function handleGetNextImage(currentImageObj) {
    executing.value = true;
    let resultImageObj = currentImageObj;
    const index = imageList.value.findIndex((img) => img.id === currentImageObj.id);
    if ((index + 1) < imagesPerPage.value) {
      const nextImageObj = imageList.value[(index + 1)];
      resultImageObj = nextImageObj;
    } else {
      currentPage.value += 1;
      // Await new images before setting next image
      const promise = new Promise((resolve, reject) => {
        getImages().then((list) => {
          imageList.value = list;
          resolve(list);
        });
      });
      await promise.then((list) => {
        const nextImageObj = list[0];
        resultImageObj = nextImageObj;
      });
    }
    executing.value = false;
    return resultImageObj;
  }

  function getHasPreviousImage(currentImageObj) {
    if (!imageList.value) {
      return false;
    }
    if (currentPage.value === 1) {
      const index = imageList.value.findIndex((img) => img.id === currentImageObj.id);
      if (index === 0) {
        return false;
      }
    }
    return true;
  }

  function getHasNextImage(currentImageObj) {
    if (!imageList.value) {
      return false;
    }
    if (currentPage.value === totalPages.value) {
      const index = imageList.value.findIndex((img) => img.id === currentImageObj.id);
      if ((index + 1) === imageList.value.length) {
        return false;
      }
    }
    return true;
  }

  return {
    datasetFilters,
    annotationDisplaySettings,
    imageDisplaySettings,
    reverse,
    combineSequenceFrames,
    sortBy,
    currentDatasetID,
    internalCurrentDataset,
    currentPage,
    totalPages,
    imagesPerPage,
    imageList,
    totalImageCount,
    getImages,
    deleteFilteredImages,
    getImagesParams,
    getImagesFilterParams,
    handleGetPreviousImage,
    handleGetNextImage,
    getHasPreviousImage,
    getHasNextImage,
    setImageParams,
    setDatasetFilters,
    executing,
    isFetchingImages,
    $reset,
  };
}
