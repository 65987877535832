<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Confidence'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <span>{{ lowerValue }}</span>
        <RangeSlider
          v-model:valueLower="lowerValue"
          v-model:valueUpper="upperValue"
          :step="step"
          :min="min"
          :max="max"
          :showMiddle="false"
        />
        <span>{{ upperValue }}</span>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import RangeSlider from '@/components/RangeSlider.vue';
import * as utils from '@/assets/js/utils.js';

export default {
  name: "ConfidenceRangeFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
    RangeSlider,
  },
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      min: 0,
      max: 1,
      lowerValue: 0.0,
      upperValue: 1.0,
      step: 0.01,
    };
  },
  computed: {
    isActive() {
      return this.lowerValue > 0 || this.upperValue < 1;
    },
    tagValue() {
      return `${this.lowerValue.toFixed(2)} - ${this.upperValue.toFixed(2)}`;
    },
  },
  watch: {
    lowerValue() {
      this.debouncedUpdateFilters();
    },
    upperValue() {
      this.debouncedUpdateFilters();
    },
    modelValue() {
      if (this.modelValue) {
        this.lowerValue = this.modelValue[0];
        this.upperValue = this.modelValue[1];
      }
    },
  },
  created() {
    this.debouncedUpdateFilters = utils.debounce(this.updateFilters, 600);
  },
  methods: {
    async updateFilters() {
      if (!this.isActive) {
        this.$emit('update:modelValue', null);
        return;
      }
      this.$emit('update:modelValue', [this.lowerValue, this.upperValue]);
    },
    clearSelection() {
      this.lowerValue = 0.0;
      this.upperValue = 1.0;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  min-width: 350px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & span {
    width: 40px;
    text-align: center;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}
</style>
