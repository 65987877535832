<template>
  <FilterPopper v-model:show="isOpen">
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Camera Source'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <Transition name="fade" mode="out-in">
          <div v-if="cameraSources && cameraSources.length > 0">
            <div v-for="(source, i) in cameraSources" :key="i" class="filter-settings__row">
              <input
                :id="`cameraSourceFilter-source-${i}`"
                v-model="selectedCameraSources"
                type="checkbox"
                :name="`${source}-${i}`"
                :value="source"
              >
              <label :for="`source-${i}`">{{ source }}</label><br>
            </div>
          </div>
          <div v-else-if="cameraSources">
            No camera sources found.
          </div>
          <div v-else>
            <v-skeleton-loader
              type="list-item"
              theme="primary"
              color="transparent"
            />
            <v-skeleton-loader
              type="list-item"
              theme="primary"
              color="transparent"
            />
          </div>
        </Transition>
      </div>

      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import DatastoreConnect from '../../../assets/js/DatastoreFunctions/datastore-interface';

export default {
  name: "CameraSourceFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      isOpen: false,
      selectedCameraSources: [],
      cameraSources: null,
    };
  },
  computed: {
    isActive() {
      return this.selectedCameraSources.length > 0;
    },
    tagValue() {
      return this.selectedCameraSources.length.toString();
    },
    currentDataset() {
      return this.$store.state.datasets.currentDataset;
    },
  },
  watch: {
    modelValue(newVal) {
      this.selectedCameraSources = newVal;
    },
    selectedCameraSources() {
      this.updateFilters();
    },
    isOpen(isOpen) {
      if (isOpen) {
        const dataConnect = new DatastoreConnect();
        dataConnect.getDatasetImageSources({ dataset_id: this.dataset.id })
          .then((resp) => {
            if (resp.error) {
              throw resp.error;
            }
            this.cameraSources = resp.result;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    currentDataset() {
      this.cameraSources = null;
      this.selectedCameraSources = [];
    },
  },
  methods: {
    updateFilters() {
      this.$emit('update:modelValue', this.selectedCameraSources);
    },
    clearSelection() {
      this.selectedCameraSources = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: inherit;
  gap: 6px;
  min-width: 200px;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

:deep(.v-skeleton-loader__list-item) {
  margin: 4px;
}
</style>
