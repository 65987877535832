<template>
  <div class="image-details">
    <ul class="image-details__list scrollbar">
      <li class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'hashtag'"
            :width="20"
            :height="20"
          />
        </div>
        <div class="image-details__list-item-details">
          <span :title="`${ imageObj.id }`">{{ imageObj.id }}</span>
        </div>
      </li>
      <li v-if="options.showName" class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'image'"
            :width="20"
            :height="20"
          />
        </div>
        <div class="image-details__list-item-details">
          <span :title="`${ imageObj.name }`">{{ imageObj.name }}</span>
          <div class="image-details__list-item-sub-details">
            <span :title="`${ imageObj.width }w x ${ imageObj.height }h`">{{ imageObj.width }}w x {{ imageObj.height }}h</span>
          </div>
        </div>
      </li>
      <li v-if="options.showDate" class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'event'"
            :width="20"
            :height="20"
          />
        </div>
        <div class="image-details__list-item-details">
          <span :title="(new Date(imageObj.date)).toUTCString()">{{ (new Date(imageObj.date)).toUTCString() }}</span>
        </div>
      </li>
      <li v-if="options.showFileLocation" class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'folder'"
            :width="20"
            :height="20"
          />
        </div>
        <div class="image-details__list-item-details">
          <span>File Location</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="imageObj.url">{{ imageObj.url }}</span>
          </div>
          <!-- <span v-if="options.showExternalURL">External URL</span>
          <div v-if="options.showExternalURL" class="image-details__list-item-sub-details _overflow-wrap">
            <span v-if="imageObj.ext_url" :title="imageObj.ext_url">{{ imageObj.ext_url }}</span>
            <span v-else><i>No External URL</i></span>
          </div> -->
        </div>
      </li>
      <li v-if="options.showImageLocation" class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'place'"
            :width="20"
            :height="20"
          />
        </div>
        <div class="image-details__list-item-details">
          <span>Image Location</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span :title="`${ imageObj.latitude }, ${ imageObj.longitude }`">{{ imageObj.latitude }}, {{ imageObj.longitude }}</span>
          </div>
        </div>
      </li>
      <li v-if="options.showCameraSource" class="image-details__list-item">
        <div class="image-details__list-item-icon">
          <SVGIcon
            :iconName="'photo_camera'"
            :width="20"
            :height="20"
          />
        </div>
        <div class="image-details__list-item-details">
          <span>Camera</span>
          <div class="image-details__list-item-sub-details _overflow-wrap">
            <span v-if="imageObj.source" :title="imageObj.source">{{ imageObj.source }}</span>
            <span v-else><i>Unknown Device</i></span>
          </div>
        </div>
      </li>
      <li v-if="groups.length > 0" class="image-details__list-item">
        <div class="image-group">
          <label class="image-group__label">Group</label>
          <ImageGroupSelector v-model="imageGroupID" :groups="groups" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import ImageGroupSelector from '@/components/ImageGroupSelector.vue';

export default {
  name: 'ImageDetails',
  components: {
    SVGIcon,
    ImageGroupSelector,
  },
  props: {
    imageObj: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => ({
        showName: true,
        showDate: true,
        showFileLocation: true,
        showExternalURL: true,
        showImageLocation: true,
        showCameraSource: true,
      }),
    },
    sourceDataset: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['image-group-changed'],
  data() {
    return {
      imageGroupID: null,
    };
  },
  computed: {
    groups() {
      if (this.sourceDataset?.groups) {
        return this.sourceDataset.groups;
      }
      return [];
    },
  },
  watch: {
    imageObj() {
      this.imageGroupID = this.imageObj.group_id;
    },
    imageGroupID() {
      this.$emit('image-group-changed', (this.imageGroupID || null));
    },
  },
};
</script>

<style lang="scss" scoped>
.image-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  max-width: 300px;
  padding: 0;
  z-index: 2;

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    gap: 8px;
    overflow-y: auto;
    list-style-type: none;
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 16px;
  }

  &__list-item-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 40px;
    min-width: 40px;
  }

  &__list-item-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-align: left;
    overflow: hidden;

    span {
      font-weight: 600;
      font-size: 0.9rem;
      line-height: 1.1rem;
      overflow: hidden;
      margin-bottom: 2px;
      overflow-wrap: anywhere;
    }
  }

  &__list-item-sub-details {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    text-align: left;

    span {
      font-weight: 500;
      font-size: 0.9rem;
      @include themify() {
        color: themed('body-text-color-secondary');
      }
    }

    &._overflow-wrap span {
      white-space: unset;
      overflow-wrap: anywhere;
    }
  }
}

.image-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__label {
    color: var(--body-text-color-secondary);
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
}
</style>
