<template>
  <FilterPopper>
    <template #default="{ menuOpen }">
      <BaseFilterButton
        :name="'Time Range'"
        :active="isActive"
        :tagValue="tagValue"
        :menuOpen="menuOpen"
      />
    </template>
    <template #popper>
      <div class="filter-settings scrollbar">
        <div class="slider-container">
          <span>{{ Math.floor( lowerValue ) }}:{{ lowerValue % 1 ? '30' : '00' }}</span>
          <RangeSlider
            v-model:valueLower="lowerValue"
            v-model:valueUpper="upperValue"
            :step="step"
            :min="min"
            :max="max"
            :showMiddle="false"
            :flip="flip"
          />
          <IconButton
            class="filter-settings__flip-slider mx-1"
            :class="{'_active': flip}"
            :height="'24px'"
            :width="'24px'"
            :icon="'flip'"
            @click="flip = !flip"
          />
          <span>{{ Math.floor( upperValue ) }}:{{ upperValue % 1 ? '30' : '00' }}</span>
        </div>
        <p class="disclaimer">Time ranges displayed are in UTC.</p>
      </div>
      <div class="filter-footer">
        <button :disabled="!isActive" class="button-text clear-button" @click="clearSelection">Clear Selection</button>
      </div>
    </template>
  </FilterPopper>
</template>

<script>
import IconButton from '@/components/IconButton.vue';
import FilterPopper from '@/components/FilterPopper.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import RangeSlider from '@/components/RangeSlider.vue';
import * as utils from '@/assets/js/utils.js';

export default {
  name: "TimeRangeFilter",
  components: {
    FilterPopper,
    BaseFilterButton,
    RangeSlider,
    IconButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      min: 0,
      max: 24,
      lowerValue: 0,
      upperValue: 24,
      step: 0.5,
      flip: false,
    };
  },
  computed: {
    isActive() {
      return this.lowerValue !== 0 || this.upperValue !== 24;
    },
    tagValue() {
      const lower = `${Math.floor(this.lowerValue)}:${this.lowerValue % 1 ? '30' : '00'}`;
      const upper = `${Math.floor(this.upperValue)}:${this.upperValue % 1 ? '30' : '00'}`;
      if (this.flip) {
        return `${upper}-${lower}`;
      }
      return `${lower}-${upper}`;
    },
  },
  watch: {
    lowerValue() {
      this.debouncedUpdateFilters();
    },
    upperValue() {
      this.debouncedUpdateFilters();
    },
    flip() {
      this.debouncedUpdateFilters();
    },
    modelValue() {
      this.lowerValue = this.timeToDecimal(this.modelValue[0]);
      this.upperValue = this.timeToDecimal(this.modelValue[1]);
    },
  },
  created() {
    this.debouncedUpdateFilters = utils.debounce(this.updateFilters, 600);
  },
  methods: {
    timeToDecimal(timeStr) {
    // Split the string by the colon
      const [hours, minutes] = timeStr.split(':').map(Number);

      // Convert hours and minutes to decimal
      const decimalTime = hours + (minutes / 60);

      return decimalTime;
    },
    updateFilters() {
      if (!this.isActive) {
        this.$emit('update:modelValue', null);
        return;
      }

      if (this.flip) {
        this.$emit('update:modelValue', [
          `${Math.floor(this.upperValue)}:${this.upperValue % 1 ? '30' : '00'}`,
          `${Math.floor(this.lowerValue)}:${this.lowerValue % 1 ? '30' : '00'}`,
        ]);
      } else {
        this.$emit('update:modelValue', [
          `${Math.floor(this.lowerValue)}:${this.lowerValue % 1 ? '30' : '00'}`,
          `${Math.floor(this.upperValue)}:${this.upperValue % 1 ? '30' : '00'}`,
        ]);
      }
    },
    clearSelection() {
      this.lowerValue = 0;
      this.upperValue = 24;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-settings {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: inherit;
  gap: 6px;
  min-width: 350px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &__row {
    display: flex;
    align-items: center;
  }

  & input[type=checkbox] {
    margin-right: 12px;
  }

  & span {
    width: 40px;
  }

  &__flip-slider._active {
    transform: rotate(180deg);
  }
}

.filter-footer {
  min-height: 38px;
  width: 100%;
  border-top: 1px solid rgba(140,140,140,0.375);
  padding: 8px;
  text-align: center;

  .clear-button {
    @include themify() {
      color: themed('color-danger');
    }

    &[disabled] {
      @include themify() {
        color: themed('color-disabled');
      }
    }
  }
}

.slider-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}

.disclaimer {
    margin-top: 2px;
    text-align: left;
    font-weight: 600;
    font-style: italic;
    line-height: 1.15;
    font-size: 0.875rem;
    color: var(--body-text-color-secondary);
  }
</style>
