<template>
  <div class="app-page">
    <header class="header">
      <div class="nav-group">
        <img class="header__logo" :src="require('@/assets/img/auzoneLogo.svg')" alt="">
        <span @click="navigateToMain">Deep View Enterprise</span>
        <span @click="navigateToPricing">Pricing</span>
        <span @click="navigateToHelp">Help</span>
      </div>
      <!-- <h1>Deep View Enterprise</h1> -->
      <router-link class="button login-btn" to="/login">Login</router-link>
    </header>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'WelcomeLanding',
  methods: {
    navigateToMain() {
      this.$router.push('/home');
    },
    navigateToPricing() {
      this.$router.push('/pricing');
    },
    navigateToHelp() {
      window.location.replace("https://help.dveml.com"); 
    },
  },
};
</script>

<style lang="scss" scoped>
.app-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  background-color: var(--color-primary);
}

$header-height: 50px;
.header {
  display: flex;
  align-items: center;
  font-size: 1rem;
  justify-content: space-between;
  gap: 15px;
  color: var(--primary-text-color);
  background-color: var(--color-primary);
  padding: 16px 16% 16px 16%;
  height: $header-height;
  z-index: 10;

  &__logo {
    width: 100px;
    height: 50px;
    padding: 6px 6px 6px 6px;
  }
}

.nav-group {
  display: flex;
  flex-direction:  row;
  align-items: center;

  span {
    padding: 0 20px;
    font-size: 1.0rem;
    font-weight: 600;
    cursor: pointer;
  }
  span:hover {
    color: rgb(201, 200, 200);
  }
}

.login-btn {
  background-color: var(--color-primary-400);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    background-color: var(--color-primary-300);
  }
}

@media (max-width: $breakpoint-md) {
  .header {
    padding: 0;
    line-height: 1;
    width: 100%;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
  }

  .nav-group {
    span {
      padding: 0 10px;
      font-size: 1.0rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
